import '../App.css';
import MainNavigation from "../components/MainNavigation";
import { Outlet } from "react-router-dom";
import { DocumentsContextProvider } from "../context/DocumentsContext";
import { AgentsContextProvider } from '../context/AgentsContext'
import { RouteGuardContextProvider } from "../context/RouteGuardContext";

function RootLayout() {
    return (
        <div className="app">
            <RouteGuardContextProvider>
                <MainNavigation/>
                <DocumentsContextProvider>
                    <AgentsContextProvider>
                        <Outlet/>
                    </AgentsContextProvider>
                </DocumentsContextProvider>
            </RouteGuardContextProvider>
        </div>
    )
}

export default RootLayout;
