import Box from "@mui/material/Box";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";


// TODO reuse
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 530,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 4
};

export default function AgentDeleteModal({open, onClose, onDelete, agent}) {
    const [agentDeleteAllowed, setAgentDeleteAllowed] = useState(false);

    const validateInput = (event) => {
        event === "DELETE" ? setAgentDeleteAllowed(true) : setAgentDeleteAllowed(false)
    }

    return (
        <Modal open={open}
               onKeyDown={(e) => {
                   if (e.key === 'Enter' && agentDeleteAllowed) onDelete()
               }}
        >
            <Box sx={style}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    sx={{position: 'absolute', top: 20, right: 30}}
                >
                    <CloseIcon/>
                </IconButton>

                <Typography id="modal-modal-title" variant="h5" gutterBottom>Delete Agent</Typography>

                <p style={{marginTop: '40px'}}>Are you sure you would like to delete agent "{agent.name}"?</p>
                <p>This will destroy the agent permanently and cannot be undone.</p>
                <p>Please type “DELETE” to confirm.</p>

                <TextField
                    required
                    fullWidth
                    autoFocus
                    id="outlined-required"
                    label="Type DELETE"
                    onChange={(e) => validateInput(e.target.value)}
                    sx={{marginTop: '20px'}}
                />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 2,
                    justifyContent: 'space-between',
                    marginTop: '50px'
                }}>
                    <Button
                        variant="text"
                        onClick={onClose}>
                        Cancel
                    </Button>

                    <Button variant="contained"
                            disabled={!agentDeleteAllowed}
                            onClick={onDelete}>
                        DELETE
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}