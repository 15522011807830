import React, {useContext, useEffect, useRef, useState} from "react";
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {Button} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import {handleData} from "../helpers/dataHandler";
import {DocumentsContext} from "../context/DocumentsContext";
import { checkForDuplicates } from "../helpers/checkForDuplicates";


export function FileUpload({onInit, hideUploadForma, receivedFile, onFileUpload}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [dragActive, setDragActive] = useState(false);

    // const [uploadInProgress, setUploadInProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    const uploadButtonRef = useRef(null);
    const inputRef = useRef(null);

    const {files, fetchFiles, setSelectedFileIDs} = useContext(DocumentsContext);

    // We use this for random ID on fileUploads Input.
    // In some cases we have 2 Upload Forms active, so they must have unique identifiers in order to work properly
    const inputId = `input-file-upload-${Math.random().toString(36).substr(2, 9)}`;

    const api = process.env.REACT_APP_API;

    // Will trigger if files selected outside FileUpload component, passed by "props.receivedFile",
    // Can be file dragAndDropped into Documents Table, or upload button in table toolbar.
    useEffect(() => {
        if (receivedFile) {
            setSelectedFile(receivedFile);
            setTimeout(() => triggerUpload());
        }
    }, [receivedFile]);
    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setSelectedFile(e.dataTransfer.files[0]);

            setTimeout(() => triggerUpload())
        }
    };
    // triggers when file is selected with click
    const handleChange = async function (e) {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
            setTimeout(() => triggerUpload())
        }
    };
    const handleUpload = async () => {
        if (!selectedFile) {
            alert("Please first select a file");
            return;
        }

        if (checkForDuplicates(files, selectedFile.name)) {
            alert("File with same name already exists");
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);

        setLoading(true);
        onInit();

        handleData('POST', `${api}/files/upload`, formData)
            .then((response) => {
                if (response) {
                    setSelectedFile(null);
                    inputRef.current.value = '';

                    setSelectedFileIDs(prevState => {
                        return [...prevState, response.file_id]
                    })
                    fetchFiles();
                    onFileUpload(true);

                    setTimeout(() => setLoading(false), 500);
                }
            })
            .catch((error) => {
                onFileUpload(false);
                setTimeout(() => setLoading(false), 500);
                console.error('Server not responds:', error);
            })
    };

    // trigger file upload after file is selected
    const triggerUpload = () => {
        uploadButtonRef.current.click();
    };

    return (
        <div className={`form-file-upload ${hideUploadForma ? "hide" : ""} ${loading ? "disabled" : ""}`}
             onDragEnter={handleDrag}>

            <input type="file"
                   id={inputId}
                   ref={inputRef}
                   className={'input-file-upload'}
                   onChange={handleChange}/>
            <label id={inputId}
                   htmlFor={inputId}
                   className={`label-file-upload ${dragActive ? "drag-active" : ""}`}>
                <div>
                    <UploadFileOutlinedIcon sx={{fontSize: 80}}/>
                    <p style={{fontSize:'20px', lineHeight: '10px'}}>Select documents to upload</p>
                    <p>or drag and drop them here</p>

                    <Button onClick={handleUpload}
                            ref={uploadButtonRef}
                            style={{display: "none"}}>
                        Upload
                    </Button>
                </div>
            </label>

            {dragActive &&
                <div id="drag-file-element"
                     onDragEnter={handleDrag}
                     onDragLeave={handleDrag}
                     onDragOver={handleDrag}
                     onDrop={handleDrop}>
                </div>
            }

            {loading && (
                <CircularProgress
                    size={30}
                    sx={{
                        color: '#1d85ee',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        zIndex: '1',
                        marginLeft: '-15px'
                    }}
                />
            )}
        </div>
    )
}