import { createContext, useContext, useEffect, useState } from 'react';
import { handleData } from "../helpers/dataHandler";
import { RouteGuardContext } from "./RouteGuardContext";

const api = process.env.REACT_APP_API;

export const AgentsContext = createContext({
    agents: [],
    prompts: [{}],
    fetchAgents: () => {},
    api: api
})


export const AgentsContextProvider = ({children}) => {
    const [agents, setAgents] = useState([]);
    const [prompts, setPrompts] = useState([]);

    const { isLoggedIn } = useContext(RouteGuardContext);

    useEffect(() => {
        if (isLoggedIn) {
            setTimeout(() => {
                fetchAgents();
                fetchPrompts();
            }, 1000) // prevent jumping if fast response, better UX
        }
    }, [isLoggedIn]);

    const fetchAgents = () => {
        handleData("GET", `${api}/agents/`)
            .then((data) => data && data.length ? setAgents(data.reverse()) : setAgents([]))
    }

    const fetchPrompts = () => {
        handleData("GET", `${api}/prompt/list_prompts`)
            .then(data => setPrompts(data));
    }

    return (
        <AgentsContext.Provider value={{
            agents: agents,
            prompts: prompts,
            fetchAgents: fetchAgents,
            api: api,
        }}>
            {children}
        </AgentsContext.Provider>
    );
}


