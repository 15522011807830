import Stack from "@mui/material/Stack";
import { Alert, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function AgentMainInfo({agent, prompts, onInfoUpdated, timeUpdated}) {
    const scriptAttr = agent.metadata.chat_widget_customization.script_attr;

    const scriptTag = scriptAttr === 'none' ? '' : scriptAttr;
    const chatWidgetUrl = process.env.REACT_APP_API;
    const script_url = `<script src="${chatWidgetUrl}" data-chat-id="${agent.id}" ${scriptTag}></script>`;

    const agentInfo = {
        name: agent.name,
        prompt: agent.prompt.id,
        greeting: agent.greeting
    }

    const [updatedAgentInfo, setUpdatedAgentInfo] = useState(agentInfo);

    const infoChanged = JSON.stringify(agentInfo) !== JSON.stringify(updatedAgentInfo);

    useEffect(() => {
        infoChanged ? onInfoUpdated(updatedAgentInfo) : onInfoUpdated(null);

    }, [updatedAgentInfo]);

    return (
        <div>
            <Typography id="modal-modal-title" variant="h5" gutterBottom>Agent: {agent.name}</Typography>
            <Stack>
                <Stack direction="row" spacing={5} style={{color: "grey"}}>
                    <small>Prompt Name: <b>{agent.prompt.name}</b></small>
                    <small>Owner: <b>{agent.user.username}</b></small>
                    <small>Created: <b>missing</b></small>
                    {timeUpdated && <small>Updated: <b>{timeUpdated}</b></small>}
                </Stack>
            </Stack>

            <Stack direction="row" sx={{marginTop: '40px'}}>
                <Stack sx={{width: '70%'}} spacing={2}>
                    <TextField
                        size="small"
                        label="Agent Name"
                        variant="outlined"
                        value={updatedAgentInfo.name}
                        onChange={(e) => setUpdatedAgentInfo({...updatedAgentInfo, name: e.target.value})}
                        sx={{backgroundColor: 'white'}}
                        fullWidth/>

                    <TextField
                        size="small"
                        label="Welcome Message"
                        variant="outlined"
                        value={updatedAgentInfo.greeting}
                        onChange={(e) => setUpdatedAgentInfo({...updatedAgentInfo, greeting: e.target.value})}
                        sx={{backgroundColor: 'white'}}
                        fullWidth/>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Prompt</InputLabel>
                        <Select
                            size="small"
                            value={updatedAgentInfo.prompt}
                            label="Select Prompt"
                            onChange={(e) => setUpdatedAgentInfo({...updatedAgentInfo, prompt: e.target.value})}
                            sx={{backgroundColor: 'white'}}
                        >
                            {prompts.map((prompt) => (
                                <MenuItem key={prompt.id} value={prompt.id}>{prompt.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack sx={{width: '30%', justifyContent: "center", alignItems: "flex-end"}}>
                    {agent.metadata.avatar &&
                        <Avatar
                            alt={agent.name}
                            src={`/assets/animals/${agent.metadata.avatar.name}.png`}
                            sx={{
                                backgroundColor: `${agent.metadata.avatar.color}`,
                                width: 126,
                                height: 123,
                                marginRight: 2,
                                padding: 2
                            }}
                        />
                    }
                </Stack>
            </Stack>

            <Tooltip title="Include this code snippet into your website to be able to use this Agent and Chat Widget.">
                <Alert sx={{marginTop: '40px', cursor: 'pointer'}} variant="outlined" severity="info" icon={<ContentCopyIcon/>}>
                    <code onClick={() => navigator.clipboard.writeText(script_url)}
                          style={{fontSize: "13px"}}>{script_url}</code>
                </Alert>
            </Tooltip>
        </div>
    )
}