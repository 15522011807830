// React
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';

// Mui
import { GridActionsCellItem } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import {Alert, Modal, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from '@mui/icons-material/Download';

// Own
import HorizontalLinearStepper from "../components/stepper/Stepper";
import sizeConverter from "../helpers/sizeConverter";
import { FileUpload } from "../components/FileUpload";
import { Table } from "../components/Table";
import { tableToolbar } from "../helpers/tableToolbar";
import { downloadFile } from "../helpers/fileDownload";
import { DocumentsContext } from "../context/DocumentsContext";
import { AgentsContext } from "../context/AgentsContext";
import { modalStyle } from "../helpers/styleHelper";
import { RouteGuardContext } from "../context/RouteGuardContext";

const agentStyle = {
    height: '35px',
    width: '35px',
    border:'2px solid white',
    borderRadius: '100%',
    padding: '5px',
    marginLeft: '-8px',
    position: 'relative',
}
const agentImages = ({row}) => {
    return(row.used_by_agents.length > 0 && row.used_by_agents.slice(0,3).map((agent,index) => {
        const avatarName = agent.metadata.avatar?.name;
        const avatarColor = agent.metadata.avatar?.color;
        const imagePath = `/assets/animals/${avatarName}.png`;
        return (
            <img
                key={index}
                src={imagePath}
                alt={avatarName}
                style={{
                    ...agentStyle,
                    backgroundColor:avatarColor,
                    zIndex: `-${index}`
                }}
            />
        );
    }));
}

const onHover = (params, agents) => {
    const ids = params.row.used_by_agents.map((agent) => agent.id)
    const arr = agents.filter((agent) => ids.includes(agent.id));
    return (
        <ul>
            {arr.map(agent => <p style={{marginLeft: '-35px'}} key={agent.id}>{agent.name}</p>).reverse()}
        </ul>
    );
}


export default function Documents() {
    const [modalState, setModalState] = useReducer(state => !state, false);
    const [snackBar, setSnackBar] = useState({open: false});

    // Handle Files dragged into Table or clicking "Add Documents" in Table Toolbar
    const [fileForUpload, setFileForUpload] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    const [loading, setLoading] = useState(true);

    const fileInputRef = useRef(null);

    const {isLoggedIn} = useContext(RouteGuardContext);
    const {files, deleteFile} = useContext(DocumentsContext);
    const {agents} = useContext(AgentsContext);

    useEffect(() => {
        (files && files.length > 0) && setTimeout(() => setLoading(false), 1000);
    }, [files])

    const tableConfig = {
        columns: [
            {field: 'name', headerName: 'Name', minWidth: 280, flex: 1},
            {
                field: 'used_by_agents',
                headerName: 'Agent',
                minWidth: 120,
                renderCell: (params) => {
                    return (
                        <Tooltip title={onHover(params, agents)} placement={"top-start"}>
                            <div style={{display: 'flex'}}>
                                {agentImages(params)}
                                {params.row.used_by_agents.length > 3 &&
                                    <p style={{
                                        ...agentStyle,
                                        backgroundColor:'#BDBDBD',
                                        zIndex: '-4',
                                        marginTop: 0,
                                        marginBottom:0,
                                        fontSize:'15px',
                                        color:'white'
                                    }}>+{params.row.used_by_agents.length - 3}</p>
                                }
                            </div>
                        </Tooltip>
                    );
                },
            },
            {field: 'type', headerName: 'Type', minWidth: 150},
            {
                field: 'size', headerName: 'Size', type: 'number',
                valueFormatter: (params) => sizeConverter(params.value)
            },
            {
                field: 'updated', headerName: 'Modified', minWidth: 60,
                valueFormatter: (params) => new Date(params.value).toLocaleDateString()
            },
            {
                field: 'actions',
                type: 'actions',
                getActions: (params) => {
                    return [
                        <GridActionsCellItem
                            icon={<DownloadIcon/>}
                            onClick={() => downloadFile(params.row.uploaded_file, params.row.name)}
                            label="Download"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            onClick={() => {
                                if(params.row.used_by_agents.length > 0){
                                    alert('File is in use by agents and cannot be deleted!');
                                } else {
                                    deleteFile(params.id);
                                    tableConfig.documentDeletedSnackBar();
                                }
                            }}
                            label="Delete"
                        />
                    ]
                }
            }
        ],
        toolbar: () => tableToolbar(fileInputRef, setFileForUpload, setModalState),
        documentDeletedSnackBar: () => {
            setSnackBar({
                open: true,
                autoHideDuration: 3000,
                severity: "success",
                message: 'File Deleted Successfully'
            })
        }
    };


    // TODO DRY (drag area too)
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFileForUpload(e.dataTransfer.files[0]);
            setDragActive(false);
        }
    };

    if (!isLoggedIn) {
        return null;
    }

    return (
        <>
            <div className={`${dragActive ? "table dragActive" : "table"} ${loading ? "table disabled" : ""}`}
                 onDragEnter={handleDrag}
                 onDragLeave={handleDrag}
                 onDragOver={handleDrag}
                 onDrop={handleDrop}>

                <Table
                    tableData={files}
                    tableCols={tableConfig.columns}
                    tableToolbar={tableConfig.toolbar}
                    allowRowSelection={true}
                />

                {loading && (
                    <CircularProgress
                        size={30}
                        sx={{color: '#1d85ee', position: 'absolute', top: '50%', left: '50%', zIndex: '1'}}
                    />
                )}
            </div>

            <br/>

            <FileUpload
                onInit={() => setLoading(true)}
                hideUploadForma={files.length > 0}
                receivedFile={fileForUpload}
                onFileUpload={(uploadSuccessful) => {
                    setSnackBar({
                        open: true,
                        autoHideDuration: 3000,
                        severity: uploadSuccessful ? "success" : "error",
                        message: uploadSuccessful ? "Document Uploaded Successfully" : 'Server not respond'
                    });
                    !uploadSuccessful && setTimeout(() => setLoading(false), 500);
                }}
            />

            {snackBar.open &&
                <Snackbar
                    open={snackBar.open}
                    autoHideDuration={snackBar.autoHideDuration}
                    onClose={() => setSnackBar({open: false})}
                    transitionDuration={0}>
                    <Alert severity={snackBar.severity}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>
            }

            {/*   STEPPER MODAL (Agent Creation)   */}
            <Modal open={modalState}>
                <Box sx={modalStyle}>
                    <HorizontalLinearStepper
                        onCloseModal={() => setModalState(false)}
                        onAgentCreated={(createdSuccessful) => {
                            setSnackBar({
                                open: true,
                                autoHideDuration: 3000,
                                severity: createdSuccessful ? "success" : "error",
                                message: createdSuccessful
                                    ?
                                    "Agent Added Successfully, deployment in process..."
                                    :
                                    "Failed to create an Agent, please try later."
                            });
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
}
