import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";

const TextNotification = ({ message, status }) => {
    return (
        <span style={{ display: "inline-flex", alignItems: "center" }}>
            <span style={{ color: status ? "green" : "#ab0000" }}>{message}</span>
            {status ? <CloudDoneIcon sx={{ color: "green", ml: 1 }} /> : <CloudOffIcon sx={{ color: "#ab0000", ml: 1 }} />}
        </span>
    );
};

export const toggleNotification = (setNotification, { isActive, message, status, duration = 5000 }) => {
    setNotification({ isActive, message, status });

    // Automatically hide the notification after the specified duration
    if (isActive) {
        setTimeout(() => {
            setNotification(prevState => ({ ...prevState, isActive: false }));
        }, duration);
    }
};

export default TextNotification;