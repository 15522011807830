export async function handleData(method, url, data = null, headers = {}) {
    try {
        const controller = new AbortController();
        const { signal } = controller;

        const options = {
            method,
            signal,
            headers: headers,
            credentials: "include"
        };

        if (data) {
            options.body = data;
        }

        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();

    } catch (error) {
        throw new Error(`Fetch error: ${error.message}`);
    }
}
