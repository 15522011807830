import { useEffect, useState } from "react";
import { Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";

export default function AgentAdvancedOptions({agent, onAdvancedOptionUpdate}) {

    const options = {
        minimize_on_init: agent.metadata.chat_widget_customization.minimize_on_init,
        script_attr: agent.metadata.chat_widget_customization.script_attr,
        domain: agent.metadata.chat_widget_customization.domain,
        is_active: agent.metadata.chat_widget_customization.is_active,
    }

    const [advancedOptions, setAdvancedOptions] = useState(options);

    const optionsChanged = JSON.stringify(options) !== JSON.stringify(advancedOptions);

    const handleAttributeChange = (event, newAttribute) => {
        newAttribute && setAdvancedOptions((prevState) => {
            return {...prevState, script_attr: newAttribute}
        });

        onAdvancedOptionUpdate(advancedOptions);
    };

    const handleToggleState = (e) => {
        setAdvancedOptions((prevState) => {
            return {...prevState, minimize_on_init: e.target.checked}
        });
    }

    const handleDomainChange = (e) => {
        setAdvancedOptions((prevState) => {
            return {...prevState, domain: e.target.value}
        });
    }

    const handleActiveState = (e) => {
        setAdvancedOptions((prevState) => {
            return {...prevState, is_active: e.target.checked}
        });
    }

    useEffect(() => {
        optionsChanged ? onAdvancedOptionUpdate(advancedOptions) : onAdvancedOptionUpdate(null);
    }, [advancedOptions])

    return (
        <>
            <p><strong>Define domain on which Widget should be used.</strong></p>
            <p>To prevent unauthorised use, Widget is only possible to use on domain defined here.</p>
            <TextField
                size="small"
                required
                fullWidth
                value={advancedOptions.domain}
                sx={{width: '300px'}}
                label="domain"
                helperText="without https/www > example: domain.com"
                onChange={handleDomainChange}
            />
            <br/>
            <br/>

            <p><strong>Define how embedded script is downloaded and executed</strong></p>
            <ToggleButtonGroup
                color="primary"
                value={advancedOptions.script_attr}
                exclusive
                onChange={handleAttributeChange}
                size="small">
                <ToggleButton value="async">Async</ToggleButton>
                <ToggleButton value="defer">Defer</ToggleButton>
                <ToggleButton value="none" style={{color: advancedOptions.script_attr === "none" ? "red" : ''}}>
                    none
                </ToggleButton>
            </ToggleButtonGroup>
            <br/>
            <br/>

            <p>Script with <code className="code">defer</code> attribute will not block page loading and will be
                executed when the DOM is ready but before <code className="code">DOMContentLoaded</code> event.</p>
            <p>Script with <code className="code">async</code> attribute also not block page loading, but it loads in
                the background and executes as soon as it is available.</p>
            <p>Without those attributes, the script will be downloaded and executed immediately in the order it appears
                in the HTML document, blocking rendering rest of the page until the script has been fully downloaded and
                executed.
            </p>
            <br/>

            <p><strong>Define Widget active state</strong></p>
            <p>By setting the Widget to Inactive state, Widget will not load on your page.</p>
            <Switch checked={advancedOptions.is_active}
                    onChange={handleActiveState}/>
            <br/>
            <br/>

            <p><strong>Load Chat Widget minimized</strong></p>
            <p>By default, as page loads, Widget will be hidden and can be toggled by clicking Widget Icon in bottom
                right corner. Disabling this option, widget will appear open on load.</p>
            <Switch checked={advancedOptions.minimize_on_init}
                    onChange={handleToggleState}/>
            <br/>
            <br/>
        </>
    );
}