// React
import { useContext, useEffect, useState } from "react";

// Material
import { TextField, Avatar } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

// Own
import { AgentsContext } from '../../context/AgentsContext.js';
import { checkForDuplicates } from "../../helpers/checkForDuplicates";

export default function Step2({newAgent, prompts, onInfoChanged}) {

    const { agents} = useContext(AgentsContext);
    const [error, setError] = useState(false);

    useEffect(() => {
        newAgent.name = !error ? newAgent.name : "";
    }, [error, newAgent.name]);

    return (
        <div>
            <Stack direction="row" spacing={2} sx={{marginTop: "40px"}}>

                <Avatar src={`/assets/animals/${newAgent.metadata.avatar.name}.png`} alt=""
                        sx={{ backgroundColor: newAgent.metadata.avatar.color,
                            flexShrink: "0", height: "56px", width: "56px", borderRadius: "100%", padding: "10px" }}/>

                <TextField
                    value={newAgent.name}
                    error={error}
                    helperText={ error ? 'Name already exists, please choose different one.' : ''}
                    required
                    fullWidth
                    size="small"
                    label="Agent Name"
                    onChange={(e) => onInfoChanged("name", e.target.value)}
                    onBlur={(e) => setError(checkForDuplicates(agents, e.target.value))}
                />
            </Stack>
            <br/>
            <TextField
                value={newAgent.greeting}
                required
                fullWidth
                size="small"
                label="Welcome Message"
                onChange={(e) => onInfoChanged("greeting", e.target.value)}
            />
            <br/>
            <br/>
            <FormControl fullWidth>
                <InputLabel>Select Prompt</InputLabel>
                <Select
                    value={newAgent.prompt ? newAgent.prompt : ""}
                    label="Select Prompt *"
                    size="small"
                    onChange={(e) => onInfoChanged("prompt", e.target.value)}>
                    {prompts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br/>
            <br/>
            <TextField
                value={newAgent.metadata.chat_widget_customization.domain}
                required
                fullWidth
                size="small"
                placeholder="Type in Domain where widget will be used"
                label="Domain"
                onChange={(e) => onInfoChanged("domain", e.target.value)}
            />
            <br/>
            <br/>
        </div>
    );
}