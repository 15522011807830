import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Documents from "./pages/Documents";
import Agents from "./pages/Agents";
import Prompts from "./pages/Prompts";
import RootLayout from "./pages/Root";
import AgentDetails from "./pages/AgentDetails";
import Login from "./pages/Login";

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout/>,
        children: [
            {path: '/', element: <Login/>},
            {path: 'documents', element: <Documents/>},
            {path: 'agents/', element: <Agents/>},
            {path: 'agents/:id', element: <AgentDetails/>},
            {path: 'prompts', element: <Prompts/>}
        ]
    }
]);

function App() {
    return <RouterProvider router={router}/>;
}

export default App;
