import { createContext, useContext, useEffect, useState } from "react";
import { handleData } from "../helpers/dataHandler";
import { RouteGuardContext } from "./RouteGuardContext";

const api = process.env.REACT_APP_API;

export const DocumentsContext = createContext({
    files: [],
    selectedFiles: [],
    selectedFileIDs: [],
    deleteFile: () => {},
    fetchFiles: () => {},
    setSelectedFileIDs: () => {}
});

export const DocumentsContextProvider = ({children}) => {
    const [files, setFiles] = useState([]);
    const [selectedFileIDs, setSelectedFileIDs] = useState([]);

    const { isLoggedIn } = useContext(RouteGuardContext);

    // Get Files objects from Files IDs
    const selectedFiles = files.filter(file => selectedFileIDs.includes(file.id));

    useEffect(() => {
        if (isLoggedIn) {
            setTimeout(() => {
                fetchFiles();
            }, 1000) // prevent jumping if fast response, better UX
        }
    }, [isLoggedIn]);

    const fetchFiles = () => {
        handleData("GET", `${api}/files/files`)
            .then(data => setFiles(data.reverse()))
            .catch((error) => console.error('Server not responds:', error))
    }

    const deleteFile = (id) => {
        handleData("DELETE", `${api}/files/delete-files`, JSON.stringify([id]))
            .then(() => setFiles(files.filter((file) => file.id !== id)))
    }

    return (
        <DocumentsContext.Provider value={{
            files: files,
            deleteFile: deleteFile,
            fetchFiles: fetchFiles,
            selectedFiles: selectedFiles,
            selectedFileIDs: selectedFileIDs,
            setSelectedFileIDs: setSelectedFileIDs
        }}>
            {children}
        </DocumentsContext.Provider>
    )
}
