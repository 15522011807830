import { useRef, useState, useEffect } from "react";
import { handleData } from "../helpers/dataHandler";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import { CancelScheduleSend } from "@mui/icons-material";
import SendIcon from '@mui/icons-material/Send';
import { validateInput } from "../helpers/sanitizeMessage";

// Create an AbortController instance for canceling Fetch requests
const controller = new AbortController();

// Get the signal from the controller
const { signal } = controller;


export default function ChatWidget({agent, options}) {
    const [message, setMessage] = useState([]);
    const [toggle, setToggle] = useState(agent.metadata.chat_widget_customization.minimize_on_init);
    const [isSending, setIsSending] = useState(false);  // state to handle sending msg status
    const [sessionId, setSessionId] = useState(null);

    const date = new Date();

    // padStart used to add 0 for minutes, since getMinutes returns ex: 13:8 instead 13:08
    const [hour, minutes] = [date.getHours(), date.getMinutes()].map(num => String(num).padStart(2, '0'));

    const inputRef = useRef(null);
    const chatContainerRef = useRef(null);

    options = options ? options : agent.metadata.chat_widget_customization;

    useEffect(() => {
        if (chatContainerRef.current) {
            const element = chatContainerRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [message]);

    const handleMessages = () => {

        if (isSending) {
            controller.abort();
            return;
        }

        let userInput = inputRef.current.value.trim();

        if (!userInput || userInput.length === 0) return;

        if (!validateInput(userInput)) {
            const info = "Invalid input. Allowed characters are: a-z, A-Z, 0-9, spaces, commas, periods, exclamation marks, hyphens, and question marks.";

            setMessage([
                ...message,
                { message: info, role: 'user' }]
            );

            inputRef.current.value = '';
            return;
        }

        setMessage([
            ...message,
            { message: userInput, role: 'user' }]
        );

        getAnswer(userInput);
    }

    const getAnswer = (userInput) => {
        const input = { chat_in_text: userInput, metadata: {} };

        setIsSending(true);

        const headers = {
            "Content-Type": "application/json",
            "Session-ID": sessionId,
        };

        handleData(
            "POST",
            `https://api.botti.dev.bp0.de/api/chatbot/${agent.id}/chat`,
            JSON.stringify(input),
            signal,
            headers
        )
            .then((response) => {
                setMessage(prevMessages => [
                    ...prevMessages,
                    { message: formatLink(response), role: 'assistent' }
                ]);
                inputRef.current.value = '';
                setSessionId(response.session_id);
                setIsSending(false);
            })
            .catch((error) => {
                inputRef.current.value = '';
                setIsSending(false);
                console.log(error);
            });
    };


    function formatLink(response) {
        const formattedText = response.chat_out_text.replace(/(http[s]?:\/\/[^\s]+)/gi, `<a href="$1" target="_blank" rel="noopener noreferrer">LINK</a>`);
        return <span dangerouslySetInnerHTML={{ __html: formattedText }}></span>;
    }

    const handleKeyDown = (event) => {
        if (isSending) return;
        if (event.key === 'Enter') {
            handleMessages();
        }
    }

    return (
        <>
            {options &&
                <>
                    <div id="chatWidget" style={{bottom: options.position_bottom, right: options.position_right, marginBottom: 64}}>

                        {toggle &&
                            <button onClick={() => setToggle((v) => !v)}
                                    className="chatButton" style={{background: options.main_color, boxShadow: options.shadow}}>
                                <img src="/chat-widget/assets/chatStartIcon.svg" alt=""/>
                            </button>
                        }

                        <div className="chatWrapper"
                             style={{
                                 background: options.background_color,
                                 height: options.widget_height,
                                 width: options.widget_width,
                                 boxShadow: options.shadow,
                                 display: !toggle ? 'flex' : 'none'
                             }}>
                            <div className="chatHeader" style={{background: options.main_color}}>
                                <img src="/chat-widget/assets/logo.svg" alt=""/>
                                <p className="headline">{agent.name}</p>
                                <ExpandMoreIcon className="toggleIcon" onClick={() => setToggle((v) => !v)}/>
                            </div>

                            <div className="chatMessages" ref={chatContainerRef}>

                                <div className="assistent">
                                    <img className="avatar" src="/chat-widget/assets/avatar.svg" alt=""/>
                                    <div className="message">
                                    <span className="text"
                                          style={{
                                              color: options.message_text_color,
                                              background: options.message_background_color
                                          }}>
                                        {agent.greeting}
                                    </span>
                                        <span className="time" style={{color: options.message_text_color}}>{hour}:{minutes}</span>
                                    </div>
                                </div>

                                {message.map((msg, index) => (
                                    <div className={msg.role} key={index}>
                                        {msg.role === 'assistent' && <img className='avatar' src='/chat-widget/assets/avatar.svg' alt='' />}
                                        <div className="message">
                                    <span className="text"
                                          style={{
                                              color: options.message_text_color,
                                              background: options.message_background_color
                                          }}>
                                        {msg.message}
                                    </span>
                                            <span className="time" style={{color: options.message_text_color}}>{hour}:{minutes}</span>
                                        </div>
                                    </div>
                                ))
                                }

                            </div>

                            {isSending && <div className="loadingMessage">
                                <img className="loadingMessageIndicator" src="/chat-widget/assets/loading.gif" alt=""/>
                            </div>}

                            <div className="chatAction">
                                <input
                                    ref={inputRef}
                                    className="chatInput"
                                    type="text"
                                    placeholder={options.input_placeholder_text}
                                    style={{color: options.message_text_color, background: options.background_color}}
                                    onKeyDown={handleKeyDown}
                                />
                                <IconButton color="primary" aria-label="add to shopping cart"
                                            sx={{background: options.main_color}}
                                            onClick={handleMessages}
                                            disabled={isSending}
                                            size="small">
                                    {isSending
                                        ?
                                        <CancelScheduleSend fontSize="small"/>
                                        :
                                        <SendIcon fontSize="small"/>
                                    }
                                </IconButton>

                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
