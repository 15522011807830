import Stack from "@mui/material/Stack";
import { IconButton, Switch, TextField } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { ChromePicker } from "react-color";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

let body = document.getElementsByTagName("body")[0];

const handleChanges = (options, {identifier, value: value}) => {
    switch (identifier) {
        case 'inputPlaceholderText':
            return {...options, input_placeholder_text: value}
        case 'mainColor':
            return {...options, main_color: value}
        case 'backgroundColor':
            return {...options, background_color: value}
        case 'messageBackgroundColor':
            return {...options, message_background_color: value}
        case 'messageTextColor':
            return {...options, message_text_color: value}
        case 'widgetHeight':
            return {...options, widget_height: value}
        case 'widgetWidth':
            return {...options, widget_width: value}
        case 'positionBottom':
            return {...options, position_bottom: value}
        case 'positionRight':
            return {...options, position_right: value}
        case 'shadow':
            return {...options, shadow: value ? "rgba(0, 0, 0, 0.24) 0 3px 8px" : undefined}
        case 'customClass':
            return {...options, custom_class: value}
        case 'mobileMq':
            return {...options, mobile_mq: value}
        case 'resetStyle':
            return value
        default:
            throw new Error()
    }
}

export default function AgentOptions({agent, onStyleUpdate}) {
    const [options, setOptions] = useReducer(handleChanges, agent.metadata.chat_widget_customization);
    const [pageBackground, setPageBackground] = useState("#f2f2f2");

    const styleChanged = JSON.stringify(options) !== JSON.stringify(agent.metadata.chat_widget_customization);

    useEffect(() => {
        styleChanged ? onStyleUpdate(options) : onStyleUpdate(null);
    }, [options])

    useEffect(() => {
        body.style.backgroundColor = pageBackground;
    }, [pageBackground])

    return (
        <>
            {options &&
                <>
                    <p>Here you can customize your Agent. Open widget and change settings to see the changes.</p>

                    <Stack direction="row" justifyContent={"space-between"} sx={{flexWrap: "wrap"}}>
                        <TextField
                            size="small"
                            value={options.input_placeholder_text}
                            label="Input text placeholder"
                            sx={{width: '160px', marginTop: "15px"}}
                            onChange={(e) => setOptions({identifier: 'inputPlaceholderText', value: e.target.value})}
                        />
                        <ColorPicker
                            color={options.main_color}
                            label={'Widget Color'}
                            identifier={'mainColor'}
                            onColorChange={(e) => setOptions(e)}>
                        </ColorPicker>

                        <ColorPicker
                            color={options.background_color}
                            label={'Widget Background'}
                            identifier={'backgroundColor'}
                            onColorChange={(e) => setOptions(e)}>
                        </ColorPicker>

                        <ColorPicker
                            color={options.message_background_color}
                            label={'Message background'}
                            identifier={'messageBackgroundColor'}
                            onColorChange={(e) => setOptions(e)}>
                        </ColorPicker>

                        <ColorPicker
                            color={options.message_text_color}
                            label={'Message text color'}
                            identifier={'messageTextColor'}
                            onColorChange={(e) => setOptions(e)}>
                        </ColorPicker>

                        <TextField
                            size="small"
                            value={options.widget_height}
                            label="Widget Height (px)"
                            sx={{width: '160px', marginTop: "15px"}}
                            onChange={(e) => setOptions({identifier: 'widgetHeight', value: e.target.value})}
                        />
                        <TextField
                            size="small"
                            value={options.widget_width}
                            label="Widget Width (px)"
                            sx={{width: '160px', marginTop: "15px"}}
                            onChange={(e) => setOptions({identifier: 'widgetWidth', value: e.target.value})}
                        />

                        <TextField
                            size="small"
                            value={options.position_bottom}
                            label="Position Bottom (px)"
                            sx={{width: '160px', marginTop: "15px"}}
                            onChange={(e) => setOptions({identifier: 'positionBottom', value: e.target.value})}
                        />
                        <TextField
                            size="small"
                            value={options.position_right}
                            label="Position Right (px)"
                            sx={{width: '160px', marginTop: "15px"}}
                            onChange={(e) => setOptions({identifier: 'positionRight', value: e.target.value})}
                        />

                        {/*FLEX HACK Only*/}
                        <p style={{width: '160px', marginTop: "15px"}}></p>
                        <p style={{width: '160px'}}></p>
                    </Stack>

                    <Stack>
                        <p>You can override the style of the Widget by setting custom class on Widget wrapper
                            so it can be used as selector, to style it in your app. See widget class names <u>here</u>.</p>
                        <TextField
                            size="small"
                            value={options.custom_class}
                            label=".my-custom-chat"
                            sx={{width: '160px'}}
                            onChange={(e) => setOptions({identifier: 'customClass', value: e.target.value})}
                        />
                    </Stack>

                    <Stack sx={{marginTop: '20px'}}>
                        <p>Set width of your mobile breakpoint, at which widget should be 100% width and height.</p>
                        <TextField
                            size="small"
                            value={options.mobile_mq}
                            label="mobile MQ"
                            sx={{width: '160px'}}
                            onChange={(e) => setOptions({identifier: 'mobileMq', value: e.target.value})}
                        />
                    </Stack>

                    <Stack sx={{marginTop: '20px'}}>
                        <p style={{marginBottom: '0'}}>Here you can test how Widget will look on different/your website background:</p>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                            <ColorPicker
                                color={pageBackground}
                                label={'App background'}
                                identifier={'appBackground'}
                                onColorChange={(e) => setPageBackground(e.value)}>
                            </ColorPicker>
                            <IconButton sx={{marginLeft: '-33px', marginBottom: '4px'}}
                                        onClick={() => setPageBackground("#f2f2f2")}
                                        size="small">
                                <RestartAltIcon sx={{fontSize: '20px', color: '#999'}}/>
                            </IconButton>
                        </div>
                    </Stack>

                    <Stack sx={{margin: '20px 0 20px'}}>
                        <p>Widget shadow. Useful if both, your App and the Widget have white or similar background.</p>
                        <Switch checked={!!options.shadow}
                                onChange={(e) => setOptions({identifier: 'shadow', value: e.target.checked})}/>
                    </Stack>
                </>
            }
        </>
    )
}

function ColorPicker({color, identifier, label, onColorChange}) {
    const [showColorPicker, setShowColorPicker] = useState(false);

    return (
        <div style={{position: "relative"}}>
            <TextField
                size="small"
                sx={{width: '160px', marginTop: "15px"}}
                value={color}
                label={label}
                onClick={() => setShowColorPicker(true)}
            />
            {showColorPicker &&
                <div className="color-picker" onClick={(e) => {
                    e.target.className === 'color-picker' && setShowColorPicker(false)
                }}>
                    <div className="color-picker-el">
                        <ChromePicker color={color}
                                      onChange={(e) => onColorChange({identifier: identifier, value: e.hex})}
                                      disableAlpha={true}/>
                    </div>
                </div>
            }
        </div>
    )
}