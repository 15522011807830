import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export default function AgentFiles({agent}) {
    return (
        <>
            <List dense sx={{
                height: '200px',
                border: '1px solid #bfbfbf',
                borderRadius: '8px',
                overflow:'auto',
                overflowWrap: 'break-word',
                paddingRight:'10px'
            }}>
                {agent.files.map((file) => (
                    <ListItem key={file.id} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={file.name}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    )
}