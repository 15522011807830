import React, { useContext, useEffect, useState } from 'react';
import '../styles/modal.scss';
import { useNavigate } from "react-router-dom";
import { RouteGuardContext } from "../context/RouteGuardContext";

export default function Login() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { REACT_APP_USERNAME, REACT_APP_PASSWORD } = process.env;

    const { isLoggedIn, setIsLoggedIn } = useContext(RouteGuardContext);

    useEffect(() => {
        isLoggedIn && navigate('/documents');
    }, []);

    const handleLogin = () => {
        if (username === REACT_APP_USERNAME && password === REACT_APP_PASSWORD) {
            const loggedInData = {
                loggedIn: true,
                timestamp: new Date().getTime(),
            };

            localStorage.setItem('loggedIn', JSON.stringify(loggedInData));

            setIsLoggedIn(true);

            navigate('/documents');
        } else {
            setError('Invalid username or password');
        }
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    if (isLoggedIn) {
        return null;
    }

    return (
        <div className="modal-container">
            <h2 className="modal-title">Login</h2>
            <input
                className="modal-input"
                type="text"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
                onKeyDown={handleKeyPress}
            />
            <input
                className="modal-input"
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyPress}
            />

            <button className="modal-button" onClick={handleLogin}>Login</button>

            {error && <div className="error">{error}</div>}
        </div>
    );
}
