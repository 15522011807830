// React
import { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// Mui
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

// Own
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { stepper, validateSteps } from "../../helpers/stepperHelper";
import { DocumentsContext } from "../../context/DocumentsContext";
import { AgentsContext } from "../../context/AgentsContext";
import { setRandomAvatar } from "../../helpers/agentAvatarSource";
import { agentTemplate } from "../agent/agentTemplate";
import { handleData } from "../../helpers/dataHandler";

const api = process.env.REACT_APP_API;

export default function HorizontalLinearStepper(props) {
    const navigate = useNavigate();

    const { selectedFileIDs, fetchFiles, setSelectedFileIDs } = useContext(DocumentsContext);
    const { fetchAgents, prompts } = useContext(AgentsContext);

    const [activeStep, setActiveStep] = useState(0);
    const [snackBar, setSnackBar] = useState({open: false});

    const [newAgent, setNewAgent] = useState({...agentTemplate});

    useMemo(() => setAgentAvatar(), []);

    const handleNavigation = (direction) => {
        if (direction === "next") {
            const validSteps = validateSteps(activeStep, selectedFileIDs, newAgent);

            validSteps && setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        if (direction === "prev") {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }

        // Save selected files to an Agent object triggered On leaving first step
        if (activeStep === 0) {
            saveSelectedFilesToAgent();
        }
    }

    function setAgentAvatar(){
        const avatar = setRandomAvatar();

        setNewAgent((prevState) => {
            return {...prevState, metadata: {
                    avatar: avatar,
                    chat_widget_customization: {
                        ...prevState.metadata.chat_widget_customization
                    }
                }}
        })
    }

    const saveSelectedFilesToAgent = () => {
        setNewAgent((prevState) => {
            return {...prevState, files: [...selectedFileIDs]}
        })
    }

    const updateAgentInfo = (identifier, value) => {
        setNewAgent((prevState) => {
            if (identifier === 'domain') {
                return {...prevState, metadata: {
                        ...prevState.metadata,
                        chat_widget_customization: {
                            ...prevState.metadata.chat_widget_customization,
                            domain: value
                        }
                    }}
            }

            return {...prevState, [identifier]: value}
        })
    }

    const addAgent = () => {
        handleData('POST', `${api}/agents/`, JSON.stringify(newAgent))
            .then((response) => {
                if (response && response.id) {
                    props.onCloseModal();
                    props.onAgentCreated(true);
                    setSelectedFileIDs([]);

                    fetchFiles();
                    fetchAgents();

                    navigate(`/agents/${response.id}`);
                }
            })
            .catch((error) => {
                console.error('Failed to Create an Agent: ', error);
                props.onCloseModal();
                props.onAgentCreated(false);
            })
    }

    return (
        <Box sx={{width: '100%'}}>
            <GridCloseIcon style={{float: 'right', cursor: 'pointer'}}
                           sx={{fontSize: 30}}
                           onClick={() => {
                               setSelectedFileIDs([]);
                               props.onCloseModal();
                           }}/>

            <Typography id="modal-modal-title" variant="h5" gutterBottom>Create new Agent</Typography>
            <br/>

            <Stepper activeStep={activeStep}>
                {stepper.steps.map(label => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <div className="modal-inner" style={{marginTop: '10px'}}>
                <div className="modal-inner-content">
                    <p>{stepper.stepsDescription[activeStep]}</p>

                    {/*  STEP 1  */}
                    {activeStep === 0 &&
                        <Step1 setNotification={(uploadSuccessful) => {
                            setSnackBar({
                                open: true,
                                autoHideDuration: 3000,
                                severity: uploadSuccessful ? "success" : "error",
                                message: uploadSuccessful ? "Document Uploaded Successfully" : 'Server not respond'
                            });
                        }}
                        />
                    }

                    {/*  STEP 2  */}
                    {activeStep === 1 &&
                        <Step2 newAgent={newAgent} prompts={prompts} onInfoChanged={updateAgentInfo}/>
                    }

                    {/*  STEP 3  */}
                    {activeStep === 2 &&
                        <Step3 onDocEditClick={() => setActiveStep(0)}
                               onAgentOptionsClick={() => setActiveStep(1)}
                               prompts={prompts}
                               newAgent={newAgent}
                        />
                    }
                </div>

                {/*    MODAL ACTION BUTTONS   */}
                <div className="modal-inner-action-buttons">
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between'}}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0 || activeStep === stepper.steps.length}
                            onClick={() => handleNavigation("prev")}
                            sx={{mr: 1}}>
                            Back
                        </Button>

                        {activeStep === stepper.steps.length - 1
                            ?
                            <Button
                                variant="contained"
                                endIcon={<CloudUploadIcon/>}
                                onClick={addAgent}>
                                Deploy Agent
                            </Button>
                            :
                            <Button onClick={() => handleNavigation("next")}>Next</Button>
                        }
                    </Box>
                </div>
            </div>

            <Snackbar
                open={snackBar.open}
                autoHideDuration={snackBar.autoHideDuration}
                onClose={() => setSnackBar({open: false})}>
                <Alert severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
