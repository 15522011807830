import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {FileUpload} from "./FileUpload";
import { useContext } from "react";
import { DocumentsContext } from "../context/DocumentsContext";

export function Table(props) {
    const {selectedFileIDs, setSelectedFileIDs} = useContext(DocumentsContext);

    const NoDocumentsUploaded = () => (
        <FileUpload/>
    );

    return (
        <Box sx={{width: '100%'}}>
            <DataGrid
                rows={props.tableData}
                columns={props.tableCols}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                slots={{
                    toolbar: props.tableToolbar,
                    noRowsOverlay: NoDocumentsUploaded
                }}

                pageSizeOptions={[5]}
                checkboxSelection={props.allowRowSelection}
                rowSelectionModel={props.allowRowSelection ? selectedFileIDs : []}
                onRowSelectionModelChange={(Ids) => setSelectedFileIDs(Ids)}
                disableRowSelectionOnClick
            />
        </Box>
    );
}