// React
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createPortal } from "react-dom";

// Mui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid, IconButton,
} from "@mui/material";

import Stack from "@mui/material/Stack";

// Own
import { AgentsContext } from "../context/AgentsContext"
import { DocumentsContext } from "../context/DocumentsContext";
import { RouteGuardContext } from "../context/RouteGuardContext";
import { handleData } from "../helpers/dataHandler";
import AgentFiles from "../components/agent/AgentFiles";
import AgentMainInfo from "../components/agent/AgentMainInfo";
import AgentDeleteModal from "../components/agent/AgentDeleteModal";
import AgentOptions from "../components/agent/AgentOptions";
import ChatWidget from "../components/ChatWidget";
import AgentAdvancedOptions from "../components/agent/AgentAdvancedOptions";
import TextNotification, { toggleNotification } from "../helpers/textNotification";
import CircularProgress from "@mui/material/CircularProgress";

const chatWidgetEl = document.getElementById('chatWidget');
const body = document.getElementsByTagName("body")[0];

export default function AgentDetails() {
    let {id} = useParams();
    const navigate = useNavigate();

    const [agent, setAgent] = useState(null);

    // Updating Agent info, style and options
    const [updatedAgentInfo, setUpdatedAgentInfo] = useState(null);
    const [updateWidgetStyle, setUpdateWidgetStyle] = useState(false);
    const [updateAdvancedOptions, setUpdateAdvancedOptions] = useState(false);
    const [error, setError] = useState(null);

    const [isDirty, setIsDirty] = useState(false);
    const [cancelUpdate, setCancelUpdate] = useState(null);
    const [loading, setLoading] = useState(true);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const [notification, setNotification] = useState({isActive: false, status: false});
    const [updatedStamp, setUpdatedStamp] = useState();

    const {fetchAgents, prompts, api} = useContext(AgentsContext);
    const {fetchFiles} = useContext(DocumentsContext);
    const {isLoggedIn, canNavigateHandler} = useContext(RouteGuardContext);

    useEffect(() => {
        getAgentData();
    }, [cancelUpdate]);

    // Prevent navigating if changes not saved
    useEffect(() => {
        const hasChanges = !!(updatedAgentInfo || updateWidgetStyle || updateAdvancedOptions)

        setIsDirty(hasChanges);
        canNavigateHandler(!hasChanges);

        // Clean Up
        return () => {
            canNavigateHandler(true);

            // reset body background if set different in AgentOptions
            if (body.hasAttribute('style')) {
                document.body.removeAttribute('style');
            }
        };

    }, [updatedAgentInfo, updateWidgetStyle, updateAdvancedOptions]);

    // Prevent closing/refreshing tab if changes not saved
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        if (isDirty) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        // Clean Up
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty]);

    const getAgentData = () => {
        setAgent(null);
        setError(null);

        handleData("GET", `${api}/agents/${id}`)
            .then(data => {
                setTimeout(() => {
                    setAgent(data);
                    setLoading(false);
                }, 500)
            })
            .catch((error) => {
                console.error('Failed to get Agent data:', error);
                setError(error);
            })
    }

    const deleteAgent = () => {
        handleData("DELETE", `${api}/agents/${id}`)
            .then(() => {
                fetchAgents();
                fetchFiles();
                navigate('/agents');
            })
    }

    const updateAgent = () => {
        const agentUpdated = {
            ...updatedAgentInfo,
            metadata: {
                ...agent.metadata,
                chat_widget_customization: {
                    ...agent.metadata.chat_widget_customization,
                    ...updateWidgetStyle,
                    ...updateAdvancedOptions
                }
            }
        };

        handleData("PATCH", `${api}/agents/${id}`, JSON.stringify(agentUpdated))
            .then((response) => {
                if (response) {
                    getAgentData();
                    fetchAgents();
                    setUpdatedStamp((new Date).toLocaleTimeString())
                    toggleNotification(setNotification, {
                        isActive: true,
                        message: "Agent successfully updated.",
                        status: true
                    });
                }
            })
            .catch((error) => {
                console.error('Server not responds:', error);
                toggleNotification(setNotification, {
                    isActive: true,
                    message: "Sorry, Agent failed to update, please try again later.",
                    status: false
                });
            })
    }

    const toggleAccordions = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setScroll(true);
    };

    // Scroll to opened Accordion
    useEffect(() => {
        scroll && setTimeout(() => {
            body.scrollIntoView({behavior: 'smooth', block: "end"})
        }, 200)

        return () => setScroll(false);
    }, [scroll]);


    if (!isLoggedIn) {
        return null;
    }

    return (
        <>
            {agent?.id &&
                <>
                    <Box>
                        <Grid container rowSpacing={6} columnSpacing={{xs: 2, sm: 5, md: 10}}
                              style={{fontSize: "14px", paddingBottom: "100px"}}>
                            <Grid item xs={7}>
                                <AgentMainInfo
                                    agent={agent}
                                    prompts={prompts}
                                    onInfoUpdated={(e) => setUpdatedAgentInfo(e)}
                                    timeUpdated={updatedStamp}/>

                                <Accordion
                                    sx={{marginTop: '40px!important'}}
                                    expanded={expanded === 'AgentOptions'}
                                    onChange={toggleAccordions('AgentOptions')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography sx={{}}></Typography>
                                        <Typography sx={{width: '33%', flexShrink: 0}}><b>Style Customization</b></Typography>
                                        <Typography sx={{color: 'text.secondary'}} variant="body2">Change Widget Appearance</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <AgentOptions agent={agent} onStyleUpdate={(e) => setUpdateWidgetStyle(e)}/>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion
                                    expanded={expanded === 'AgentFiles'}
                                    onChange={toggleAccordions('AgentFiles')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography sx={{}}></Typography>
                                        <Typography sx={{width: '33%', flexShrink: 0}}><b>Agent Files</b></Typography>
                                        <Typography sx={{color: 'text.secondary'}} variant="body2">
                                            See Files used by the Agent ({agent.files.length})
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <AgentFiles agent={agent}/>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion
                                    expanded={expanded === 'AdvancedOptions'}
                                    onChange={toggleAccordions('AdvancedOptions')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography sx={{}}></Typography>
                                        <Typography sx={{width: '33%', flexShrink: 0}}><b>Advanced Options</b></Typography>
                                        <Typography sx={{color: 'text.secondary'}} variant="body2">Change script loading behaviour</Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <AgentAdvancedOptions agent={agent} onAdvancedOptionUpdate={setUpdateAdvancedOptions}/>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>

                        <Stack direction="row" justifyContent={"space-between"} className="action-footer">
                            <div style={{display: "flex"}}>
                                <Button variant="contained" size="small" sx={{mr: 2}}
                                        onClick={updateAgent}
                                        disabled={!isDirty}>
                                    Update Agent
                                </Button>
                                <Button variant="outlined" size="small" sx={{mr: 2}}
                                        onClick={setCancelUpdate}
                                        disabled={!isDirty}>
                                    Cancel
                                </Button>

                                {notification.isActive && (
                                    <TextNotification message={notification.message} status={notification.status}/>
                                )}
                            </div>

                            <IconButton aria-label="delete"
                                        sx={{color: "#ab0000"}}
                                        onClick={() => setOpenDeleteModal(true)}>
                                <DeleteIcon fontSize="inherit"/>
                            </IconButton>
                        </Stack>
                    </Box>

                    {createPortal(
                        <ChatWidget agent={agent} options={updateWidgetStyle}/>,
                        chatWidgetEl
                    )}

                    <Stack>
                        {openDeleteModal &&
                            <AgentDeleteModal
                                open={openDeleteModal}
                                onClose={() => setOpenDeleteModal(false)}
                                onDelete={deleteAgent}
                                agent={agent}/>}
                    </Stack>
                </>
            }

            {loading && (
                <CircularProgress
                    size={30}
                    sx={{color: '#1d85ee', position: 'absolute', top: '50%', left: '50%', zIndex: '1'}}
                />
            )}

            {(!agent?.id && error) && <p>Failed to load Agent data, please try later.
                <br/>
                <br/>
                Error: {error.message}</p>
            }
        </>
    )
}
