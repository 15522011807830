export const stepper = {
    steps: ['Select Agent Files', 'Set Agent Options', 'Agent Customization'],
    stepsDescription: [
        "Please select the documents that you would like to add to your Agent. You can also upload new documents for your Agent.", //Step 1 in Stepper Modal
        "Please give your Agent a name and decide how it greets its users. Configure the agent’s settings. All settings can be changed after Agent is created.", // Step 2
        "Please review the settings for your agent before deployment. After deploying your Agent, you can no longer edit its setting, only the prompt can be changed.", //Step 3
    ]
}

export const validateSteps = (activeStep, selectedFiles, newAgent) => {
    if (activeStep === 0) {
        if (!selectedFiles || selectedFiles.length === 0) {
            alert('Please select at least one File.')
            return false;
        }
    }

    if (activeStep === 1) {
        if (!newAgent.name || !newAgent.greeting || !newAgent.prompt) {
            alert('All fields must be filled.')
            return false;
        }
    }

    return true;
}