import EditIcon from '@mui/icons-material/Edit';
import Stack from "@mui/material/Stack";
import sizeConverter from "../../helpers/sizeConverter";
import classes from "./Stepper.module.css"
import { useContext } from "react";
import { DocumentsContext } from "../../context/DocumentsContext";

const headlineEdit = (headline, action) => {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center"
               sx={{marginTop: '10px', height: "50px"}}>
            <h4>{headline}</h4>
            <div className={classes.editDiv} onClick={action}>
                <EditIcon/>
                <p className={classes.editText}> Edit</p>
            </div>
        </Stack>
    )
}

export default function Step3({newAgent, prompts, onDocEditClick, onAgentOptionsClick}) {
    const {files, selectedFileIDs} = useContext(DocumentsContext);

    // selectedFiles are saved as IDs: number, here we set back all File properties if matches selected ID
    const selectedFileObjects = files.filter(file => selectedFileIDs.includes(file.id));

    const selectedFilesSizes = selectedFileObjects.map((item) => item.size);
    const selectedFilesSum = sizeConverter(selectedFilesSizes.reduce((acc, current) => acc + current, 0));
    const prompt = prompts.find(item => item.id === newAgent.prompt).name;

    return (
        <>
            {headlineEdit("Selected Documents", onDocEditClick)}

            <div className={classes.borderDiv}>
                <p>Number of Documents: <b>{selectedFileObjects.length}</b></p>
                <p>Total Size: <b>{selectedFilesSum}</b></p>
            </div>

            {headlineEdit("Agent Options", onAgentOptionsClick)}

            <div className={classes.borderDiv}>
                <p>Name:<b> {newAgent.name}</b></p>
                <p>Greeting: <b>{newAgent.greeting}</b></p>
                <p>Prompt: <b>{prompt}</b></p>
                <p>Domain: <b>{newAgent.metadata.chat_widget_customization.domain}</b></p>
            </div>
        </>
    );
}