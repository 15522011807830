export function validateInput(message) {
    const originalMessage = message; // Store the original content

    // Remove script tags and event attributes
    let strippedMessage = message
        .replace(/<\/?script\b[^>]*>/gi, '')
        .replace(/<[^>]+? (on\w+)=["'][^"']+"['"][^>]*>/gi, '');

    // Remove iframe elements
    strippedMessage = strippedMessage.replace(/<iframe[\s\S]*?<\/iframe>/gi, '');

    // Remove javascript: URLs
    strippedMessage = strippedMessage.replace(/<[^>]+? (href|src)=["']\s*javascript:(.*?)["'][^>]*>/gi, '');

    // Remove potentially malicious attributes
    strippedMessage = strippedMessage.replace(/\s+(on\w+)=["'][^"']*["']/gi, ' ');

    // Remove HTML comments
    strippedMessage = strippedMessage.replace(/<!--[\s\S]*?-->/g, '');

    // Remove inline style attributes
    strippedMessage = strippedMessage.replace(/<[^>]+?\s+style=(["'])(.*?)\1[^>]*>/gi, '');

    // Convert special characters to their corresponding HTML entities
    strippedMessage = strippedMessage
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')

    return originalMessage === strippedMessage;
}