import { createContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useLoginValidity from "../helpers/useLoginValidity";

export const RouteGuardContext = createContext({
    canNavigate: true,
    isLoggedIn:  false,
    setIsLoggedIn: () => {},
    canNavigateHandler: () => {}
})

export const RouteGuardContextProvider = ({children}) => {
    const navigate = useNavigate();
    const [canNavigate, setCanNavigate] = useState(true);
    const { isLoggedIn, setIsLoggedIn } = useLoginValidity();

    useEffect(() => {
        const handleStorageChange = () => {
            const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
            if (!loggedIn) {
                setIsLoggedIn(false);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (!isLoggedIn && window.location.pathname !== '/') {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const handleClick = () => {
            updateUserActivity();
        };

        // Add global click event listener
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const updateUserActivity = () => {
        const loggedInData = JSON.parse(localStorage.getItem('loggedIn'));
        if (loggedInData) {
            loggedInData.timestamp = new Date().getTime();
            localStorage.setItem('loggedIn', JSON.stringify(loggedInData));
        }
    };

    const canNavigateHandler = (condition) => {
        setCanNavigate(condition)
    }

    const loginHandler = (condition) => {
        setIsLoggedIn(condition)
    }

    return (
        <RouteGuardContext.Provider value={{
            canNavigate: canNavigate,
            isLoggedIn: isLoggedIn,
            setIsLoggedIn: loginHandler,
            canNavigateHandler: canNavigateHandler
        }}>
            {children}
        </RouteGuardContext.Provider>
    );
}
