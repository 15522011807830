// React
import React, { useState, useRef, useContext, useEffect } from 'react';

// Mui
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Alert, Modal, Avatar } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

// Own
import { Table } from "../components/Table";
import { FileUpload } from "../components/FileUpload";
import HorizontalLinearStepper from "../components/stepper/Stepper";

import { modalStyle } from "../helpers/styleHelper";
import { AgentsContext } from '../context/AgentsContext';
import { tableToolbar } from "../helpers/tableToolbar";
import { useNavigate } from "react-router-dom";
import { RouteGuardContext } from "../context/RouteGuardContext";

export default function Agents() {
    const [snackBar, setSnackBar] = useState({open: false});
    const [stepperModal, setStepperModal] = useState(false);
    const [fileForUpload, setFileForUpload] = useState(null);
    const [loading, setLoading] = useState(true);
    const fileInputRef = useRef(true);

    const {isLoggedIn} = useContext(RouteGuardContext);
    const {agents} = useContext(AgentsContext);

    const navigate = useNavigate();

    useEffect(() => {
        (agents && agents.length > 0) && setTimeout(() => setLoading(false), 1000);
    }, [agents])

    const tableConfig = {
        columns: [
            {
                field: 'avatar',
                renderCell: ({row}) => (
                    <> {row.metadata.avatar &&
                        <Avatar src={`/assets/animals/${row.metadata.avatar.name}.png`}
                                alt={row.metadata.avatar.name}
                                style={{
                                    backgroundColor: `${row.metadata.avatar.color}`,
                                    height: "36px",
                                    width: "36px",
                                    borderRadius: "100%",
                                    padding: "5px",
                                    marginRight: '20px'
                                }}/>
                        }
                        <p>{row.name}</p>
                    </>
                ),
                headerName: 'Agent',
                flex: 1,
                minWidth: 200
            },
            {
                field: 'metadata',
                valueGetter: ({row}) => row.files.length,
                headerName: 'File Count', type: 'number', maxWidth: 80
            },
            {
                field:'prompt',
                valueGetter: (param) => param.row.prompt.name,
                headerName: 'Prompt',
                minWidth: 250
            },
            {
                field: 'user',
                valueGetter: ({row}) => row.user.username,
                headerName: 'Owner',
            },
            {field: 'createdAt', headerName: 'Modified', type: 'number'},
            {field: 'deployment_status', headerName: 'Status', maxWidth: 80},
            {
                field: 'actions',
                type: 'actions',
                getActions: (params) => {
                    return [
                        <GridActionsCellItem
                            icon={<InfoOutlinedIcon/>}
                            onClick={() => navigate(`/agents/${params.id}`)}
                            label="Agent Details"
                        />
                    ]
                }
            }
        ],
        toolbar: () => tableToolbar(fileInputRef, setFileForUpload, setStepperModal)
    };


    if (!isLoggedIn) {
        return null;
    }

    return (
        <>
            <div className={`${loading ? "table disabled" : "table"}`}>
                <Table
                    tableData={agents}
                    tableCols={tableConfig.columns}
                    tableToolbar={tableConfig.toolbar}
                    allowRowSelection={false}
                />

                {loading && (
                    <CircularProgress
                        size={30}
                        sx={{color: '#1d85ee', position: 'absolute', top: '50%', left: '50%', zIndex: '1'}}
                    />
                )}
            </div>

            {/*   STEPPER MODAL (Agent Creation)   */}
            <Modal open={stepperModal}>
                <Box sx={modalStyle}>
                    <HorizontalLinearStepper
                        onCloseModal={() => setStepperModal(false)}
                        onAgentCreated={(createdSuccessful) => {
                            setSnackBar({
                                open: true,
                                autoHideDuration: 3000,
                                severity: createdSuccessful ? "success" : "error",
                                message: createdSuccessful
                                    ?
                                    "Agent Added Successfully, deployment in process..."
                                    :
                                    "Failed to create an Agent, please try later."
                            });
                        }}
                    />
                </Box>
            </Modal>

            <FileUpload
                onInit={() => setLoading(true)}
                receivedFile={fileForUpload}
                hideUploadForma={true}
                onFileUpload={(uploadSuccessful) => {
                    setSnackBar({
                        open: true,
                        autoHideDuration: 3000,
                        severity: uploadSuccessful ? "success" : "error",
                        message: uploadSuccessful ? "Document Uploaded Successfully" : 'Server not respond'
                    });
                    setTimeout(() => setLoading(false), 500)
                }}
            />

            <Snackbar
                open={snackBar.open}
                autoHideDuration={3000}
                onClose={() => setSnackBar({open: false})}>
                <Alert severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </>
    );
}
