export function downloadFile(fileUrl, fileName) {
    fetch(fileUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network error');
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Failed to download a file:', error);
        });
}