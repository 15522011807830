import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LOGOUT_TIME = 3600000;

const checkLoginValidity = () => {
    const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
    if (loggedIn) {
        const currentTime = new Date().getTime();
        const storedTime = loggedIn.timestamp;
        const timeElapsed = currentTime - storedTime;
        if (timeElapsed >= LOGOUT_TIME) {
            localStorage.removeItem('loggedIn');
            return false;
        } else {
            return true;
        }
    }
    return false;
};

const useLoginValidity = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(() => checkLoginValidity());

    useEffect(() => {
        const interval = setInterval(() => {
            if (!checkLoginValidity()) {
                setIsLoggedIn(false);
                navigate('/');
            }
        }, 60000); // Check every minute

        return () => clearInterval(interval);
    }, [navigate]);

    useEffect(() => {
        if (!isLoggedIn && window.location.pathname !== '/') {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    return { isLoggedIn, setIsLoggedIn };
};

export default useLoginValidity;
