function Prompts() {
    return (
      <>
          <br/><br/>
          <span>WIP</span>
      </>
    )
}

export default Prompts;
