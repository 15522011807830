export let agentTemplate= {
    name: "",
    files: [],
    greeting: "",
    prompt: null,
    metadata: {
        avatar: {
            name: undefined,
            color: undefined
        },
        chat_widget_customization: {
            input_placeholder_text: "Ask a question",
            main_color: "#2f2d2f",
            background_color: "#f2f2f2",
            message_background_color: "#dfdfdf",
            message_text_color: "#2c2c2c",
            widget_height: "550px",
            widget_width: "350px",
            position_bottom: "30px",
            position_right: "30px",
            mobile_mq: "479px",
            custom_class: "",
            domain: "",
            script_attr: "defer",
            is_active: true,
            minimize_on_init: true,
            shadow: "rgba(0, 0, 0, 0.24) 0 3px 8px"
        }
    }
}