export const animals = ["Anteater", "Armadillo", "Auroch", "Axolotl", "Badger", "Bat", "Beaver", "Buffalo", "Camel",
    "Capybara", "Chameleon", "Cheetah", "Chinchilla", "Chipmunk", "Chupacabra", "Cormorant", "Coyote", "Crow",
    "Dingo", "Dinosaur", "Dolphin", "Elephant", "Ferret", "Fox", "Frog", "Giraffe", "Gopher", "Grizzly", "Hedgehog",
    "Hippo", "Hyena", "Ibex", "Ifrit", "Iguana", "Jackal", "Kangaroo", "Koala", "Kraken", "Lemur", "Leopard", "Liger",
    "Llama", "Manatee", "Mink", "Monkey", "Moose", "Narwhal", "NyanCat", "Orangutan", "Otter", "Panda", "Penguin",
    "Platypus", "Pumpkin", "Python", "Quagga", "Rabbit", "Raccoon", "Rhino", "Sheep", "Shrew", "Skunk", "Squirrel",
    "Tiger", "Turtle", "Walrus", "Wolf", "Wolverine", "Wombat"
];

export const colors = ['#FF6633', '#FFB399', '#FF33FF', '#bdbd2b', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#4c914c', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#81a603', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#8a8931', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#3e6752', '#809980', '#9d9d30', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#1da900', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#46a8a8', '#6666FF'
];

export const setRandomAvatar = () => {

    let name, color;

    [animals, colors].forEach((array) => {
        const arrayLength = array.length;
        const randomIndex = Math.floor(Math.random() * arrayLength);

        if (array === animals) {
            name = animals[randomIndex];
        }

        if (array === colors) {
            color = colors[randomIndex]
        }
    })

    return {name: name, color: color};
}