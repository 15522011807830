import { GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { VisuallyHiddenInput } from "./styleHelper";
import { Button } from "@mui/material";


export const tableToolbar = (fileInputRef, setFileForUpload, setModalState) => {
    return (
        <GridToolbarContainer sx={{justifyContent: 'flex-end', padding: 4}}>
            <GridToolbarQuickFilter/>
            <GridToolbarExport/>
            <Button
                onClick={() => setTimeout(() => fileInputRef.current.click())}
                component="label"
                variant="outlined"
                size="small"
                startIcon={<UploadFileOutlinedIcon/>}>
                ADD DOCUMENTS
            </Button>
            <VisuallyHiddenInput
                ref={fileInputRef}
                type="file"
                onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                        setFileForUpload(e.target.files[0])
                    }
                }}
            />
            <Button variant="contained" size="small"
                    startIcon={<AutoFixHighOutlinedIcon/>}
                    onClick={() => setModalState(true)}>
                Create Agent
            </Button>
        </GridToolbarContainer>
    )
}