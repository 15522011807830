import { Link, useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import { Menu, Tab, Tabs } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { RouteGuardContext } from "../context/RouteGuardContext";
import MenuItem from "@mui/material/MenuItem";

function initialRoute(pathname) {
    if (pathname === '/' || pathname === '/documents') return 0;
    if (pathname.includes('agents')) return 1;
    if (pathname === '/prompts') return 2;
    return 0; // default to documents
}

function routeMap(index) {
    return index === 0 ? 'documents' : index === 1 ? 'agents' : 'prompts';
}

export default function MainNavigation() {
    let location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState(initialRoute(location.pathname));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { isLoggedIn, canNavigate, setIsLoggedIn } = useContext(RouteGuardContext);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        // Ensure correct tab is selected after login
        setValue(initialRoute(location.pathname));
    }, [location.pathname]);

    const handleChange = (event, newValue) => {
        if (!isLoggedIn) {
            event.preventDefault();
            navigate('/');
            return;
        }

        if (!canNavigate) {
            event.preventDefault();
            if (window.confirm("You have unsaved changes, clicking 'OK' will discard your changes.")) {
                setValue(newValue);
                navigate(`/${routeMap(newValue)}`);
            }
        } else {
            setValue(newValue);
            navigate(`/${routeMap(newValue)}`);
        }
    };

    const handleTabClick = (event, index) => {
        if (!isLoggedIn) {
            event.preventDefault();
            navigate('/');
            return;
        }

        if (!canNavigate && index === value) {
            event.preventDefault();
        }
    };

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('loggedIn');
        handleMenuClose();
        navigate('/');
    };


    if (!isLoggedIn) {
        return null;
    }

    return (
        <>
            <header>
                <Stack direction="row" style={{right: 50, position: "absolute"}}>
                    <Avatar src="" onClick={handleAvatarClick} style={{ cursor: 'pointer' }} />
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Stack>
                <h1>Botti</h1>
                <Box sx={{width: '100%'}}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab component={Link} label="Documents" to="/documents"
                             onClick={(event) => handleTabClick(event, 0)} />
                        <Tab component={Link} label="Agents" to="/agents"
                             onClick={(event) => handleTabClick(event, 1)} />
                        <Tab component={Link} label="Prompts" to="/prompts"
                             onClick={(event) => handleTabClick(event, 2)} />
                    </Tabs>
                </Box>
                <br />
                <br />
            </header>
        </>
    );
}
