import { useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { FileUpload } from "../FileUpload";
import { DocumentsContext } from "../../context/DocumentsContext";

export default function Step1({setNotification}) {
    const {files, selectedFiles, setSelectedFileIDs} = useContext(DocumentsContext);

    return (
        <Stack spacing={5} sx={{width: '100%', marginTop: "40px"}}>
            <Autocomplete
                multiple
                size="small"
                options={files}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(event, value) => {
                    setSelectedFileIDs(value.map((file) => file.id));
                }}
                value={selectedFiles}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Documents"
                        placeholder="Files"
                    />
                )}
            />
            <FileUpload
                onInit={() => {}}
                onFileUpload={(uploadSuccessful) => setNotification(uploadSuccessful)}/>
        </Stack>
    );
}
